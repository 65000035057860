import React from 'react'
import Layout from '../layout'
import { Link } from 'gatsby'
import SEO from '../components/SEO'

export default function NotFoundPage() {
  return (
    <Layout>
      <SEO />
      <div className="container">
        <h1>Not found</h1>
        <Link to="/">
          <span>Back to index page ..</span>
        </Link>
      </div>
    </Layout>
  )
}
